import styled from "@emotion/styled";

export const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 65px;

  padding: 0 var(--m-4);
  /* Shadow used in old Push */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  /* The main content right beneath the navbar overlaps the shadow */
  z-index: 1;

  & > div {
    display: flex;

    & > div,
    & > a {
      margin-left: var(--m-3);
    }
  }

  & > div:last-of-type {
    align-items: center;
  }
`;

export const UserInfos = styled.div`
  display: flex;
  align-items: flex-end;
  margin-right: var(--m-3);

  svg {
    color: var(--grey-300);
    font-size: var(--text-lg);
  }

  p {
    margin-left: var(--m-2);
    color: var(--grey-700);
    font-size: var(--text-xs);
  }
`;

export const TopbarButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--m-2);
  font-size: var(--text-base);
  cursor: pointer;

  background-color: var(--grey-page);
  border: 1px solid #e4e4e4;

  svg {
    color: #919191;
  }
`;
