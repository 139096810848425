import * as Cookies from "js-cookie";
import { ACCESS_TOKEN, REFRESH_TOKEN, REDIRECTED } from "./variables";

const redirect = url => {
  Cookies.remove(ACCESS_TOKEN);
  Cookies.remove(REFRESH_TOKEN);
  window.location.href = `${url}&${REDIRECTED}=true`;
};

export default redirect;
