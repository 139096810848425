import React, { useEffect, useState } from "react";

import callApiRights from "services/http/callApiRights";

interface Rights {
  isAdmin: boolean;
}

export const RightsContext = React.createContext<Rights | null>(null);

const RightsProvider: React.FC = props => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const result = await callApiRights("user/current", {
          method: "GET",
        });
        if (
          result &&
          result.data.data.userGroups.some(
            (group: { id: number }) => group.id === 1
          )
        ) {
          setIsAdmin(true);
        }
      } catch {}
    })();
  }, []);

  return <RightsContext.Provider value={{ isAdmin: isAdmin }} {...props} />;
};

export const useRights = () => {
  const context = React.useContext(RightsContext);

  if (!context) {
    throw new Error("useRights must be used within a RightsProvider");
  }

  return context;
};

export default RightsProvider;
