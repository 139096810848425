import axios, { AxiosRequestConfig, AxiosPromise } from "axios";
import Cookies from "js-cookie";

export default function callApiRights(
  endpoint: string,
  { ...rest }: AxiosRequestConfig
): AxiosPromise {
  const token = Cookies.get("at");

  return axios({
    url: `${process.env.REACT_APP_API_RIGHTS}${endpoint}`,
    ...(token ? { headers: { Authorization: `Bearer ${token}` } } : {}),
    ...rest,
  });
}
