import axios from "axios";
import * as Cookies from "js-cookie";
import qs from "qs";
import setToken from "./setToken";
import redirectToLoginPage from "./redirect";
import { REFRESH_TOKEN, ACCESS_TOKEN, DAID, REDIRECTED } from "./variables";

let isTokenRefreshing = false;

const refreshToken = (endpoint, clientId, redirectOnError = null) => {
  return new Promise((resolve, reject) => {
    const refreshTokenFromCookies = Cookies.get(REFRESH_TOKEN);

    // if refresh token cookie is setted
    if (refreshTokenFromCookies) {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
          grant_type: "refresh_token",
          refresh_token: refreshTokenFromCookies,
          client_id: clientId,
        }),
        url: `${endpoint}oauth2/token`,
      };

      // simple access token refresh
      axios(options)
        .then(response => {
          if (!response) {
            reject();
          } else {
            const { refresh_token, access_token } = response.data;
            setToken(refresh_token, access_token);
            resolve(access_token);
          }
        })
        .catch(() => {
          reject();
        });
      // if no token cookie setted and token is not already being refreshed
    } else if (!isTokenRefreshing) {
      isTokenRefreshing = true;

      const { search, origin, pathname } = window.location;

      const redirectUrl = `${origin}${pathname}`;

      const paramsURL = {};

      // get params from URL and prevent duplicate
      search
        .substring(1)
        .split("&")
        .map(param => {
          const [label, value] = param.split("=");
          return (paramsURL[label] = value);
        });

      const refreshTokenFromURL = paramsURL[REFRESH_TOKEN];
      const accessTokenFromURL = paramsURL[ACCESS_TOKEN];

      // if no refresh token cookie setted and no couple token from URL, throw error
      if (!refreshTokenFromURL || !accessTokenFromURL) {
        if (paramsURL[REDIRECTED]) {
          reject("No access token or refresh token");
        } else {
          reject();
          if (redirectOnError) redirectToLoginPage(redirectOnError);
        }
      } else {
        setToken(refreshTokenFromURL, accessTokenFromURL, paramsURL[DAID]);

        // remove unwanted params to display
        delete paramsURL[ACCESS_TOKEN];
        delete paramsURL[REFRESH_TOKEN];
        delete paramsURL[DAID];

        // update url dynamicaly
        window.history.pushState(
          null,
          null,
          `${redirectUrl}${
            Object.keys(paramsURL).length > 0
              ? `?${Object.keys(paramsURL)
                  .map(param => `${param}=${paramsURL[param]}`)
                  .join("&")}`
              : ""
          } `
        );

        isTokenRefreshing = false;
        resolve(accessTokenFromURL);
      }
    }
  });
};

export default refreshToken;
