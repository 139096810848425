import { css } from "@emotion/core";

export default css`
  @font-face {
    font-family: "Maison Neue";
    src: url("/fonts/MaisonNeue-Light.woff2") format("woff2"),
      url("/fonts/MaisonNeue-Light.woff") format("woff"),
      url("/fonts/MaisonNeue-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "Maison Neue";
    src: url("/fonts/MaisonNeue-LightItalic.woff2") format("woff2"),
      url("/fonts/MaisonNeue-LightItalic.woff") format("woff"),
      url("/fonts/MaisonNeue-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: "Maison Neue";
    src: url("/fonts/MaisonNeue-Demi.woff2") format("woff2"),
      url("/fonts/MaisonNeue-Demi.woff") format("woff"),
      url("/fonts/MaisonNeue-Demi.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Maison Neue";
    src: url("/fonts/MaisonNeue-DemiItalic.woff2") format("woff2"),
      url("/fonts/MaisonNeue-DemiItalic.woff") format("woff"),
      url("/fonts/MaisonNeue-DemiItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: "Maison Neue";
    src: url("/fonts/MaisonNeue-Medium.woff2") format("woff2"),
      url("/fonts/MaisonNeue-Medium.woff") format("woff"),
      url("/fonts/MaisonNeue-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "Maison Neue";
    src: url("/fonts/MaisonNeue-MediumItalic.woff2") format("woff2"),
      url("/fonts/MaisonNeue-MediumItalic.woff") format("woff"),
      url("/fonts/MaisonNeue-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: "Maison Neue";
    src: url("/fonts/MaisonNeue-Bold.woff2") format("woff2"),
      url("/fonts/MaisonNeue-Bold.woff") format("woff"),
      url("/fonts/MaisonNeue-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "Maison Neue";
    src: url("/fonts/MaisonNeue-BoldItalic.woff2") format("woff2"),
      url("/fonts/MaisonNeue-BoldItalic.woff") format("woff"),
      url("/fonts/MaisonNeue-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
  }

  :root {
    --mint: #26a69a;
    --mint-light: #26a69a29;

    --red: hsl(0, 85%, 51%);
    --red-light: hsl(0, 70%, 90%);

    --pink: hsl(340, 77%, 60%);
    --pink-light: hsl(337, 60%, 90%);
    --pink-dark: hsl(343, 80%, 55%);

    --vegetal: #31514a;

    /* Same background-color as automation */
    --grey-page: #f5f5f5;

    --grey-100: #ddd;
    --grey-300: #888;
    --grey-500: #666;
    --grey-700: #444;
    --grey-900: #222;

    --small-radius: 5px;
    --medium-radius: 10px;

    --m-1: 0.25rem;
    --m-2: 0.5rem; /* 8px */
    --m-3: 0.75rem;
    --m-4: 1rem; /* 16px */
    --m-5: 1.5rem;
    --m-6: 2rem; /* 32 px */
    --m-7: 3rem;
    --m-8: 4rem;

    --text-xs: 0.75rem;
    --text-sm: 0.875rem;
    --text-base: 1rem;
    --text-lg: 1.125rem;
    --text-xl: 1.25rem;
    --text-2xl: 1.5rem;
    --text-3xl: 1.875rem;
    --text-4xl: 2.25rem;
    --text-5xl: 3rem;

    --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  html {
    box-sizing: border-box;
  }

  body {
    font-family: "Maison Neue";
    font-weight: 500;
  }

  p {
    margin: 0;
  }

  * {
    box-sizing: inherit;
  }

  [data-reach-dialog-overlay] {
    padding-left: 300px !important;
  }
`;
