import React from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

import { mutate } from "components/RequestsManager";
import { LOCAL_LANG } from "../../i18n";
import Select from "../shared/Select";

const ChangeLanguage = () => {
  const { t, i18n } = useTranslation();

  const langs = [
    { value: "fr", label: t("topbar.changeLanguage.fr") },
    { value: "en", label: t("topbar.changeLanguage.en") },
    { value: "es", label: t("topbar.changeLanguage.es") },
  ];

  const currentLang =
    langs.find(lang => lang.value === i18n.language) || langs[0];

  const changeLanguage = (locale: string) => {
    window.localStorage.setItem(LOCAL_LANG, locale);
    i18n.changeLanguage(locale);
    mutate({
      endpoint: `${process.env.REACT_APP_SSO_URL}api/user/lang/${locale}`,
      token: Cookies.get("at"),
    });
  };

  return (
    <Select
      value={currentLang}
      options={langs}
      onSelect={changeLanguage}
      data-testid="select-lang"
    />
  );
};

export default ChangeLanguage;
