import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faCommentAltDots,
  faClock,
  faChartBar,
  faUserAltSlash,
} from "@fortawesome/pro-solid-svg-icons";

import mindbazLogo from "../../assets/mindbaz.png";
import smsLogo from "../../assets/sms.svg";

import {
  Container,
  Header,
  Links,
  Informations,
  Footer,
} from "./Sidebar.styles";

const Sidebar: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>
        <img src={smsLogo} alt="Sms Logo" />
      </Header>
      <Links>
        <Link to="/">
          <FontAwesomeIcon icon={faHome} />
          <span>{t("sidebar.home")}</span>
        </Link>
        <Link to="/in-preparation-campaign">
          <FontAwesomeIcon icon={faCommentAltDots} />
          <span>{t("sidebar.inPreparation")}</span>
        </Link>
        <Link to="/scheduled-campaign">
          <FontAwesomeIcon icon={faClock} />
          <span>{t("sidebar.programmed")}</span>
        </Link>
        <Link to="/statistics">
          <FontAwesomeIcon icon={faChartBar} />
          <span>{t("sidebar.stats")}</span>
        </Link>
        <Link to="/blacklist">
          <FontAwesomeIcon icon={faUserAltSlash} />
          <span>{t("sidebar.blacklist")}</span>
        </Link>
      </Links>

      <Footer>
        <Informations>
          <p>{t("sidebar.footer.text")}</p>
          <a href="mailto:serviceclient@mindbaz.com">
            serviceclient@mindbaz.com
          </a>
          <span>+33 (0)3 74 09 86 00</span>
          <span>+34 912 694 750</span>
        </Informations>
        <img src={mindbazLogo} alt="Mindbaz Logo" />
      </Footer>
    </Container>
  );
};

export default Sidebar;
