import * as React from "react";
import refreshToken from "./refreshToken";
import redirectToLoginPage from "./redirect";
import setRequestsInterceptor from "./interceptor";
import Loader from "./Loader";

export const TokenContext = React.createContext(null);

const TokenProvider = ({ children, endpoint, appId, redirectUrl }) => {
  const [needToRedirect, setRedirect] = React.useState(false);
  const [isReadyToRender, setReady] = React.useState(false);

  // if app is rendered, set axios interceptor to listen API calls (refresh token on 401 code errors)
  if (isReadyToRender) {
    setRequestsInterceptor({
      endpoint,
      appId,
      onRefreshTokenFailed: () => {
        setRedirect(true);
      },
    });
  } else {
    refreshToken(endpoint, appId)
      .then(() => {
        setReady(true);
      })
      .catch(error => {
        if (error) throw new Error(error);

        setRedirect(true);
      });
  }

  // if refresh and access tokens are invalid, redirect to login page
  React.useEffect(() => {
    if (needToRedirect) redirectToLoginPage(redirectUrl);
  }, [needToRedirect, redirectUrl]);

  const contextValue = React.useMemo(
    () => ({ refreshToken, endpoint, appId, redirectUrl }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // render app when token is refreshed
  return needToRedirect ? null : isReadyToRender ? (
    <TokenContext.Provider value={contextValue}>
      {children}
    </TokenContext.Provider>
  ) : (
    <Loader />
  );
};

export default TokenProvider;
