import Cookies from "js-cookie";

const {
  REACT_APP_APPLICATION_ID,
  REACT_APP_BASE_URL,
  REACT_APP_LOGIN_URL,
} = process.env;

export function redirectToLoginPage() {
  const daid = Cookies.get("daid") !== undefined ? Cookies.get("daid") : "";

  window.location.href = `
    ${REACT_APP_LOGIN_URL}
    "Account/Login?returnUrl=${REACT_APP_BASE_URL}login_callback&brand_id=${REACT_APP_APPLICATION_ID}&daid=${daid}`;
}

export function redirectToLogoutPage() {
  window.location.href = `
    ${REACT_APP_LOGIN_URL}Account/Logout?returnUrl=${window.location.href}`;
}

export function handleDisconnect() {
  Cookies.remove("at");
  Cookies.remove("rt");
  redirectToLogoutPage();
}
