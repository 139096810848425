import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faPowerOff, faCog } from "@fortawesome/pro-solid-svg-icons";
import { useHistory } from "react-router-dom";

import { useCompany } from "../providers/CompanyProvider";

import { handleDisconnect } from "services/http/handle-disconnect";

import ChangeLanguage from "./ChangeLanguage";
import Select from "../shared/Select";

import { Container, UserInfos, TopbarButton } from "./Topbar.styles";
import { useRights } from "../providers/RightProvider";

const Topbar = () => {
  const { company, setCompany, data } = useCompany();
  const { isAdmin } = useRights();
  const { t } = useTranslation();
  const history = useHistory();

  if (!data) {
    return null;
  }

  const { firstName, lastName, userCompanyList } = data;

  const companies = userCompanyList
    ? userCompanyList.map(({ idCompany, companyName }) => ({
        label: companyName,
        value: String(idCompany),
      }))
    : [];

  const currentCompanyValue =
    companies.find(comp => comp.value === String(company)) ||
    t("topbar.selectBase");

  const selectCompany = (company: string) => {
    setCompany(parseInt(company));
    history.push("/");
    window.localStorage.setItem("mindbaz_sms_id_company", company);
  };

  return (
    <Container>
      <Select
        value={currentCompanyValue}
        options={companies}
        onSelect={selectCompany}
        width="200px"
      />
      <div>
        <UserInfos>
          <FontAwesomeIcon icon={faUser} />
          <p>{`${firstName} ${lastName}`}</p>
        </UserInfos>
        <ChangeLanguage />
        {isAdmin && (
          <a href={process.env.REACT_APP_ADMIN_URL}>
            <TopbarButton>
              <FontAwesomeIcon icon={faCog} />
            </TopbarButton>
          </a>
        )}
        <TopbarButton onClick={handleDisconnect} title="Sign Out">
          <FontAwesomeIcon icon={faPowerOff} />
        </TopbarButton>
      </div>
    </Container>
  );
};

export default Topbar;
